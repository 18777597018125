import { graphql, Link, withPrefix } from 'gatsby';
import * as React from 'react';
import { useEffect } from 'react';
import Modal from 'react-modal';

import '../../assets/sass/app.scss';
import './index.css';

import { A } from '../components/a';
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import { Location, SiteMetadata } from '../models';
import EmailPopup from '../components/email-popup';
import Seo from '../components/seo'

Modal.setAppElement('#home-version-1');

interface Props {
  data: {
    site: SiteMetadata;

  };
  location: Location;
}

const Index = ({ data, location }: Props) => {
  const [videoOpen, setVideoOpen] = React.useState(false);

  function openModal() {
    setVideoOpen(true);
  }

  function afterOpenModal() {
    console.log('after open modal');
  }

  function closeModal() {
    setVideoOpen(false);
  }

  useEffect(() => {
    // Show first content by default
    $('#pix-tabs-nav li:nth-child(1)').addClass('active');
    $('#pix-tabs-content .content').hide();
    $('#pix-tabs-content .content:nth-child(1)').show();

    // click function
    $('#pix-tabs-nav li').on('click', function () {
      $('#pix-tabs-nav li').removeClass('active');
      $(this).addClass('active');
      $('#pix-tabs-content .content').hide();

      var activeTab = $(this).find('a').attr('href');
      $(activeTab!).show();
      return false;
    });
  });

  return (
    <div id="main_content">
      <Seo title='Skillgo Home'/>
      <Modal
        isOpen={videoOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Skillgo Video"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            position: 'relative',
            border: '0',
            inset: '0',
            background: 'transparent',
            outline: 'none',
            padding: '0',
            maxWidth: '800px',
            margin: '30px auto',
          },
        }}
      >
        <div className="modal-toolbar">
          <button aria-label="Close" title="Close" onClick={closeModal}>
            ×
          </button>
        </div>
        <div className="modal-video embed-responsive embed-responsive-16by9">
          <iframe
            className="embed-responsive-item"
            width="640"
            height="385"
            src="https://www.youtube.com/embed/2XQOJ0iKiUY?autoplay=1&amp;modestbranding=1&amp;showinfo=0"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Modal>
      <EmailPopup />

      <Navbar home={true} />

      <section className="banner banner-one">
        <div className="circle-shape" data-parallax='{"y" : 230}'>
          <img src="media/banner/circle-shape.png" alt="circle" />
        </div>
        <div className="container">
          <div className="banner-content-wrap">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="banner-content">
                  <h1 className="banner-title wow pixFadeUp" data-wow-delay="0.3s">
                    Skillgo{' '}
                    <span>
                      the next <br />
                      generation of
                    </span>{' '}
                    <br />
                    authoring tools
                  </h1>

                  <p className="description wow pixFadeUp" data-wow-delay="0.5s">
                    Making learning about the learner
                  </p>

                  <Link
                    to="/getting-started"
                    className="pxs-btn banner-btn wow pixFadeUp get-started-btn mb-3 mr-3"
                    data-wow-delay="0.6s"
                  >
                    Get Started
                  </Link>
                  <A
                    onClick={openModal}
                    className="pxs-btn banner-btn wow pixFadeUp mb-3"
                    data-wow-delay="0.6s"
                  >
                    Watch Video
                  </A>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="promo-mockup wow pixFadeLeft">
                  <img
                    src="images/screenshots/screenshot-tablet-min.webp"
                    alt="mpckup"
                    className="rounded shadow"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-shape">
          <img src="media/banner/shape-bg.png" alt="" />
        </div>
      </section>

      <section className="special-offer">
        <div className="container">
          <div className='row' style={{ justifyContent: 'space-evenly' }}>
            {/* <div className="col-lg-5 section-title style-two">
              <h2 className="title wow pixFadeUp" data-wow-delay="0.2s">
                Special Offer
              </h2>

              <p className="wow pixFadeUp" data-wow-delay="0.3s">
                Unlock the potential of your course material with Skillgo!
                Bring it to us, and we'll use our cutting-edge e-learning methodology
                to create the first version of your e-learning course. You can then customize it as you like.
                With Skillgo you can elevate your course to the next level!
              </p>

              <div className="row special-offer-row special-margin">
                <div className="col-md-6 special-button">
                  <Link
                    to="/offer/"
                    className="pix-btn btn-outline wow pixFadeUp"
                    data-wow-delay="0.5s"
                  >
                    Details
                  </Link>
                </div>

                <div className="col-md-6 special-button">
                  <Link
                    to="/contact/"
                    className="pix-btn wow pixFadeUp"
                    data-wow-delay="0.5s"
                  >
                    Order
                  </Link>
                </div>
              </div>
            </div> */}

            <div className="col-lg-5 section-title style-two">
              <h2 className="title wow pixFadeUp" data-wow-delay="0.2s">
                We develop it all
              </h2>

              <p className="wow pixFadeUp" data-wow-delay="0.3s">
                We can develop your entire curriculum on demand.
                All you need to do is to send us the content in one document.
                We will create your course using Skillgo, which then you can edit by yourself.
              </p>
              <p className="wow pixFadeUp" data-wow-delay="0.3s">
                Get in touch and ask for our quote for free!
              </p>

              <div className="row">
                <div className="col-md-6 special-button">
                  <Link
                    to="/contact/"
                    className="pix-btn wow pixFadeUp"
                    data-wow-delay="0.5s"
                  >
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className="featured">
        <div className="container">
          <div className="section-title text-center wow pixFade">
            <h3 className="sub-title">Why Skillgo?</h3>
            <h2 className="title">Take your e-learning to the next level</h2>
          </div>

          <div className="row">
            <div className="col-md-4">
              <div
                className="saaspik-icon-box-wrapper style-one wow pixFadeLeft"
                data-wow-delay="0.3s"
              >
                <div className="saaspik-icon-box-icon">
                  <img src="images/front/icon_adaptive.png" alt="" />
                </div>
                <div className="pixsass-icon-box-content">
                  <h3 className="pixsass-icon-box-title" style={{ cursor: `default` }}>
                    {/* <A> */}
                    Toolkit for adaptive <br />
                    practicing
                    {/* </A> */}
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="saaspik-icon-box-wrapper style-one wow pixFadeLeft"
                data-wow-delay="0.5s"
              >
                <div className="saaspik-icon-box-icon">
                  <img src="images/front/icon_creative.png" alt="" />
                </div>
                <div className="pixsass-icon-box-content">
                  <h3 className="pixsass-icon-box-title style={{ cursor: `default` }}">
                    {/* <A> */}
                    No technological <br />
                    limits to creativity
                    {/* </A> */}
                  </h3>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div
                className="saaspik-icon-box-wrapper style-one wow pixFadeLeft"
                data-wow-delay="0.7s"
              >
                <div className="saaspik-icon-box-icon">
                  <img src="images/front/icon_interactive.png" alt="" />
                </div>
                <div className="pixsass-icon-box-content">
                  <h3 className="pixsass-icon-box-title" style={{ cursor: `default` }}>
                    {/* <A> */}
                    A wide variety of interactive <br />
                    objects and functions
                    {/* </A> */}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="editor-design">
        <div className="container">
          <div className="row">
            <div className="editure-feature-image wow pixFadeRight">
              <div className="image-one" data-parallax='{"x" : 30}'>
                <img
                  src="images/screenshots/screenshot-2-min.webp"
                  className="wow pixFadeRight rounded shadow"
                  data-wow-delay="0.3s"
                  alt="feature-image"
                />
              </div>
              <div className="image-two">
                <div className="image-two-inner image-two-vertical" data-parallax='{"x" : -30}'>
                  <img
                    src="images/screenshots/screenshot-1-min.webp"
                    className="wow pixFadeLeft rounded shadow"
                    data-wow-delay="0.5s"
                    alt="feature-image"
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 offset-lg-6">
              <div className="editor-content">
                <div className="section-title style-two">
                  <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                    Learning experience <br />
                    at its best
                  </h2>

                  <p className="wow pixFadeUp" data-wow-delay="0.5s">
                    Skillgo is an adaptive, responsive, and <br />
                    fully accessible authoring tool
                  </p>
                </div>

                <div className="description wow pixFadeUp" data-wow-delay="0.7s">
                  <p>
                    We have combined 20 years of content development experience and all our
                    methodological ideas to develop this innovative tool based on the principles of
                    differentiation, adaptivity, and test-based learning.
                  </p>

                  <Link to="/templates" className="pix-btn wow pixFadeUp" data-wow-delay="0.9s">
                    Try out our templates
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shape-bg">
          <img src="media/background/shape_bg.png" className="wow fadeInLeft" alt="shape-bg" />
        </div>
      </section>

      <section className="genera-informes">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pix-order-one">
              <div className="section-title style-two">
                <h2 className="title wow pixFadeUp">
                  E-learning development <br />
                  without compromises
                </h2>

                <p className="wow pixFadeUp" data-wow-delay="0.3s">
                  With the help of Skillgo, you can easily and efficiently develop complex
                  methodological learning materials to meet the expectations of modern learners.
                </p>
              </div>

              <ul className="list-items wow pixFadeUp" data-wow-delay="0.4s">
                <li>Reduced development time</li>
                <li>Clean, responsive UI</li>
                <li>Accessible content</li>
              </ul>

              <Link
                to="/tutorial"
                className="pix-btn btn-outline wow pixFadeUp"
                data-wow-delay="0.5s"
              >
                Check out the tutorial library
              </Link>
            </div>

            <div className="informes-feature-image">
              <div className="image-one" data-parallax='{"y" : 20}'>
                <img
                  src="images/screenshots/screenshot-4-min.webp"
                  className="wow pixFadeDown rounded shadow"
                  alt="informes"
                />
              </div>

              <div className="image-two" data-parallax='{"y" : -20}'>
                <img
                  src="images/screenshots/screenshot-5-min.webp"
                  className="wow pixFadeDown rounded shadow"
                  data-wow-delay="0.3s"
                  alt="informes"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="shape-bg">
          <img src="media/background/shape.png" className="wow fadeInRight" alt="shape-bg" />
        </div>
      </section>

      <section className="revolutionize">
        <div className="bg-angle"></div>
        <div className="container">
          <div className="section-title dark-title text-center">
            <h3 className="sub-title wow pixFadeUp">Sneak peek</h3>
            <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
              Transform your e-learning <br />
              courses today
            </h2>
          </div>

          <div id="pix-tabs" className="wow pixFadeUp" data-wow-delay="0.5s">
            <ul id="pix-tabs-nav">
              <li>
                <a href="#tab1">Dashboard </a>
              </li>
              <li>
                <a href="#tab2">Easy navigation</a>
              </li>
              <li>
                <a href="#tab3">High interactivity</a>
              </li>
              <li>
                <a href="#tab4">Printable workbook</a>
              </li>
            </ul>

            <div id="pix-tabs-content">
              <div id="tab1" className="content">
                <img src="images/screenshots/screenshot-6-min.webp" alt="dashboard" />
                <div className="shape-shadow"></div>
              </div>
              <div id="tab2" className="content">
                <img src="images/screenshots/screenshot-7-min.webp" alt="navigation" />
                <div className="shape-shadow"></div>
              </div>
              <div id="tab3" className="content">
                <img src="images/screenshots/screenshot-8-min.webp" alt="interactivity" />
                <div className="shape-shadow"></div>
              </div>

              <div id="tab4" className="content">
                <img src="images/screenshots/screenshot-9-min.png" alt="workbook" />
                <div className="shape-shadow"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="featured-two">
        <div className="container">
          <div className="section-title text-center">
            <h3 className="sub-title wow pixFadeUp">Our toolkit</h3>
            <h2 className="title wow pixFadeUp" data-wow-delay="0.2s">
              Why use Skillgo?
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div
                className="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                data-wow-delay="0.4s"
              >
                <div className="saaspik-icon-box-icon">
                  <img src="images/front/icon_adaptive70x70.png" alt="feature" />
                </div>
                <div className="pixsass-icon-box-content" style={{ cursor: `default` }}>
                  <h3 className="pixsass-icon-box-title" >
                    {/* <A> */}
                    Adaptive Practice Mode
                    {/* </A> */}
                  </h3>
                  <p>
                    Appropriate question difficulty <br />
                    for the current level <br />
                    of knowledge.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                data-wow-delay="0.5s"
              >
                <div className="saaspik-icon-box-icon">
                  <img src="images/front/icon_aspect70x70.png" alt="feature" />
                </div>
                <div className="pixsass-icon-box-content" style={{ cursor: `default` }}>
                  <h3 className="pixsass-icon-box-title">
                    {/* <A> */}
                    Aspect Selector
                    {/* </A> */}
                  </h3>

                  <p>
                    Content for several <br />
                    target groups in one <br />
                    course package.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                data-wow-delay="0.6s"
              >
                <div className="saaspik-icon-box-icon">
                  <img src="images/front/icon_workbook70x70.png" alt="feature" />
                </div>
                <div className="pixsass-icon-box-content" style={{ cursor: `default` }}>
                  <h3 className="pixsass-icon-box-title">
                    {/* <A> */}
                    PDF Workbook
                    {/* </A> */}
                  </h3>
                  <p>
                    Automatically generated <br />
                    from the course <br />
                    content.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                data-wow-delay="0.7s"
              >
                <div className="saaspik-icon-box-icon">
                  <img src="images/front/icon_resources70x70.png" alt="feature" />
                </div>
                <div className="pixsass-icon-box-content" style={{ cursor: `default` }}>
                  <h3 className="pixsass-icon-box-title">
                    {/* <A> */}
                    Glossary and Resources
                    {/* </A> */}
                  </h3>
                  <p>
                    Automatically generated <br />
                    Glossary and Resource Section <br />
                    in the menu.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                data-wow-delay="0.8s"
              >
                <div className="saaspik-icon-box-icon">
                  <img src="images/front/icon_dashboard70x70.png" alt="feature" />
                </div>
                <div className="pixsass-icon-box-content" style={{ cursor: `default` }}>
                  <h3 className="pixsass-icon-box-title">
                    {/* <A> */}
                    Dashboard
                    {/* </A> */}
                  </h3>
                  <p>
                    The most important <br />
                    course information <br />
                    in one place.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div
                className="saaspik-icon-box-wrapper style-two wow pixFadeRight"
                data-wow-delay="0.9s"
              >
                <div className="saaspik-icon-box-icon">
                  <img src="images/front/icon_toc70x70.png" alt="feature" />
                </div>
                <div className="pixsass-icon-box-content" style={{ cursor: `default` }}>
                  <h3 className="pixsass-icon-box-title">
                    {/* <A> */}
                    Flexible TOC
                    {/* </A> */}
                  </h3>
                  <p>
                    Customize the depth of <br />
                    the table of contents <br /> and content pages.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="teams">
        <div className="container">
          <div className="section-title text-center">
            <h3 className="sub-title wow pixFadeUp">Meet The Team</h3>
            <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
              Awesome Skillgo Team
            </h2>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="team-member wow pixFadeRight" data-wow-delay="0.3s">
                <div className="member-avater">
                  <img src="media/team/1.jpg" alt="avater" />

                  <ul className="member-social">
                    <li>
                      <a href="https://www.linkedin.com/in/bernadett-gyurics-aa184474/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>

                  <svg
                    className="layer-one"
                    xmlns="http://www.w3.org/2000/svg"
                    width="370px"
                    height="210px"
                  >
                    <path
                      fillRule="evenodd"
                      opacity="0.302"
                      fill="rgb(250, 112, 112)"
                      d="M-0.000,79.999 C-0.000,79.999 85.262,-11.383 187.324,50.502 C297.703,117.429 370.000,-0.001 370.000,-0.001 L370.000,203.999 C370.000,207.313 367.314,209.999 364.000,209.999 L6.000,209.999 C2.686,209.999 -0.000,207.313 -0.000,203.999 L-0.000,79.999 Z"
                    />
                  </svg>

                  <svg
                    className="layer-two"
                    xmlns="http://www.w3.org/2000/svg"
                    width="370px"
                    height="218px"
                  >
                    <path
                      fillRule="evenodd"
                      opacity="0.8"
                      fill="rgb(250, 112, 112)"
                      d="M-0.000,27.999 C-0.000,27.999 95.694,-46.224 188.615,48.781 C278.036,140.208 370.000,57.999 370.000,57.999 L370.000,211.999 C370.000,215.313 367.314,217.999 364.000,217.999 L6.000,217.999 C2.686,217.999 -0.000,215.313 -0.000,211.999 L-0.000,27.999 Z"
                    />
                  </svg>
                </div>

                <div className="team-info">
                  <h3 className="name">Bernadett Gyurics</h3>
                  <h4 className="job">Head of E-learning Unit</h4>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="team-member wow pixFadeRight" data-wow-delay="0.5s">
                <div className="member-avater">
                  <img src="media/team/2.jpg" alt="avater" />

                  <ul className="member-social">
                    <li>
                      <a href="https://www.linkedin.com/in/szazo/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>

                  <svg
                    className="layer-one"
                    xmlns="http://www.w3.org/2000/svg"
                    width="370px"
                    height="210px"
                  >
                    <path
                      fillRule="evenodd"
                      opacity="0.302"
                      fill="rgb(250, 112, 112)"
                      d="M-0.000,79.999 C-0.000,79.999 85.262,-11.383 187.324,50.502 C297.703,117.429 370.000,-0.001 370.000,-0.001 L370.000,203.999 C370.000,207.313 367.314,209.999 364.000,209.999 L6.000,209.999 C2.686,209.999 -0.000,207.313 -0.000,203.999 L-0.000,79.999 Z"
                    />
                  </svg>

                  <svg
                    className="layer-two"
                    xmlns="http://www.w3.org/2000/svg"
                    width="370px"
                    height="218px"
                  >
                    <path
                      fillRule="evenodd"
                      opacity="0.8"
                      fill="rgb(250, 112, 112)"
                      d="M-0.000,27.999 C-0.000,27.999 95.694,-46.224 188.615,48.781 C278.036,140.208 370.000,57.999 370.000,57.999 L370.000,211.999 C370.000,215.313 367.314,217.999 364.000,217.999 L6.000,217.999 C2.686,217.999 -0.000,215.313 -0.000,211.999 L-0.000,27.999 Z"
                    />
                  </svg>
                </div>

                <div className="team-info">
                  <h3 className="name">Zoltán Szarvas</h3>
                  <h4 className="job">Developer</h4>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="team-member wow pixFadeRight" data-wow-delay="0.7s">
                <div className="member-avater">
                  <img src="media/team/3.jpg" alt="avater" />

                  <ul className="member-social">
                    <li>
                      <a href="https://www.linkedin.com/in/mariann-t%C3%B3th-27022664/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>

                  <svg
                    className="layer-one"
                    xmlns="http://www.w3.org/2000/svg"
                    width="370px"
                    height="210px"
                  >
                    <path
                      fillRule="evenodd"
                      opacity="0.302"
                      fill="rgb(250, 112, 112)"
                      d="M-0.000,79.999 C-0.000,79.999 85.262,-11.383 187.324,50.502 C297.703,117.429 370.000,-0.001 370.000,-0.001 L370.000,203.999 C370.000,207.313 367.314,209.999 364.000,209.999 L6.000,209.999 C2.686,209.999 -0.000,207.313 -0.000,203.999 L-0.000,79.999 Z"
                    />
                  </svg>

                  <svg
                    className="layer-two"
                    xmlns="http://www.w3.org/2000/svg"
                    width="370px"
                    height="218px"
                  >
                    <path
                      fillRule="evenodd"
                      opacity="0.8"
                      fill="rgb(250, 112, 112)"
                      d="M-0.000,27.999 C-0.000,27.999 95.694,-46.224 188.615,48.781 C278.036,140.208 370.000,57.999 370.000,57.999 L370.000,211.999 C370.000,215.313 367.314,217.999 364.000,217.999 L6.000,217.999 C2.686,217.999 -0.000,215.313 -0.000,211.999 L-0.000,27.999 Z"
                    />
                  </svg>
                </div>

                <div className="team-info">
                  <h3 className="name">Mariann Szarvas-Tóth</h3>
                  <h4 className="job">Educational Expert</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="brand-logo-ab">
        <div className="section-title text-center">
          <h2 className="title wow pixFadeUp">Our Partners</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center align-items-center">
              <img
                src={withPrefix(`assets/img/otp-bank-logo.svg`)}
                width="175"
                height="42"
                className="mx-3 mx-md-5"
              />
              <img
                src={withPrefix(`assets/img/mavir-logo.svg`)}
                width="90"
                className="mx-3 mx-md-5"
              />
              <img
                src={withPrefix(`assets/img/mvm_logo.png`)}
                width="175"
                className="mx-3 mx-md-5"
              />                  
              <img
                src={withPrefix(`assets/img/bm_logo.png`)}
                width="175"
                className="mx-3 mx-md-5"
              />   
              <img
                src={withPrefix(`assets/img/bagazs_logo2.png`)}
                width="100"
                className="mx-3 mx-md-5"
              />    
            </div>
          </div>
        </div>
      </section>

      <section className="testimonials swiper-init">
        <div className="container">
          <div className="section-title text-center">
            <h3 className="sub-title wow pixFadeUp">Testimonial</h3>
            <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
              What our client say about Skillgo
            </h2>
          </div>

          <div className="testimonial-wrapper wow pixFadeUp" data-wow-delay="0.5s">
            <div
              className="swiper-container"
              id="testimonial"
              data-speed="700"
              data-autoplay="5000"
            >
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="testimonial">
                    <div className="single-bio-thumb">
                      <img src={withPrefix('media/blog/auth2.jpg')} alt="testimonial" />
                    </div>

                    <div className="testimonial-content">
                      <p>
                        You guys rock! I STRONGLY recommend Skillgo to
                        EVERYONE interested in running a successful e-learning business!
                      </p>
                    </div>

                    <div className="bio-info">
                      <h3 className="name">Aram Doyl</h3>
                      <span className="job"></span>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial">
                    <div className="single-bio-thumb">
                      <img src={withPrefix('media/blog/auth2.jpg')} alt="testimonial" />
                    </div>

                    <div className="testimonial-content">
                      <p>
                        Skillgo is great. Great job, I will definitely be use it!
                        Thank you for making the online teaching painless,
                        pleasant and most of all hassle free!
                      </p>
                    </div>

                    <div className="bio-info">
                      <h3 className="name">Noel Arrow</h3>
                      <span className="job"></span>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial">
                    <div className="single-bio-thumb">
                      <img src={withPrefix('media/blog/auth2.jpg')} alt="testimonial" />
                    </div>

                    <div className="testimonial-content">
                      <p>
                        It really saves me time and effort. Skillgo is exactly what
                        our business has been lacking.
                      </p>
                    </div>

                    <div className="bio-info">
                      <h3 className="name">Katy Chavon</h3>
                      <span className="job"></span>
                    </div>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="testimonial">
                    <div className="single-bio-thumb">
                      <img src={withPrefix('media/blog/auth2.jpg')} alt="testimonial" />
                    </div>

                    <div className="testimonial-content">
                      <p>
                        Skillgo impressed me on multiple levels.
                      </p>
                    </div>

                    <div className="bio-info">
                      <h3 className="name">Károly Balogh</h3>
                      <span className="job"></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shape-shadow"></div>
            <div className="slider-nav wow pixFadeUp" data-wow-delay="0.3s">
              <div id="slide-prev" className="swiper-button-prev">
                <i className="ei ei-arrow_left"></i>
              </div>
              <div id="slide-next" className=" swiper-button-next">
                <i className="ei ei-arrow_right"></i>
              </div>
            </div>
          </div>
        </div>

        <div className="scroll-circle wow pixFadeDown">
          <img src="media/background/circle9.png" data-parallax='{"y" : 250}' alt="circle" />
        </div>
      </section>

      <section className="pricing">
        <div id="faq-anchor"></div>
        <div className="faq-section">
          <div className="container">
            <div className="section-title text-center">
              <h3 className="sub-title wow pixFadeUp">Frequently Asked Questions</h3>
              <h2 className="title wow pixFadeUp" data-wow-delay="0.3s">
                Want to ask something from us?
              </h2>
            </div>

            <div className="tabs-wrapper wow pixFadeUp" data-wow-delay="0.4s">
              <ul className="nav faq-tabs" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="design-tab"
                    data-toggle="tab"
                    href="#design"
                    role="tab"
                    aria-controls="design"
                    aria-selected="false"
                  >
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="service-tab"
                    data-toggle="tab"
                    href="#service"
                    role="tab"
                    aria-controls="service"
                    aria-selected="false"
                  >
                    Support
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="general-tab"
                    data-toggle="tab"
                    href="#general"
                    role="tab"
                    aria-controls="general"
                    aria-selected="false"
                  >
                    Courses
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    id="branding-tab"
                    data-toggle="tab"
                    href="#branding"
                    role="tab"
                    aria-controls="branding"
                    aria-selected="false"
                  >
                    LMS Compatibility
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="design"
                  role="tabpanel"
                  aria-labelledby="design-tab"
                >
                  <div id="accordion" className="faq faq-two pixFade">
                    <div className="card">
                      <div className="card-header" id="heading100">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse001"
                            aria-expanded="false"
                            aria-controls="collapse001"
                          >
                            How much does Skillgo cost?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse001"
                        className="collapse"
                        aria-labelledby="heading100"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>It is free to use up to 15.000 characters! <br/> Should you require
                            more content we have
                            <Link to="/pricing/"> various plans </Link>
                            to choose from.</p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading200">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse100"
                            aria-expanded="true"
                            aria-controls="collapse100"
                          >
                            I'd like to have Skillgo courses, but I am not an instructional
                            designer.
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse100"
                        className="collapse"
                        aria-labelledby="heading200"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>No worries, anyone can try it for free! Be sure to check out
                            the tutorials to have an easy start.</p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading300">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse200"
                            aria-expanded="false"
                            aria-controls="collapse200"
                          >
                            Is there a trial period?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse200"
                        className="collapse"
                        aria-labelledby="heading300"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p>You can try it for free up to 15.000 characters.</p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading400">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse300"
                            aria-expanded="false"
                            aria-controls="collapse300"
                          >
                            Do you have custome pricing options?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse300"
                        className="collapse"
                        aria-labelledby="heading400"
                        data-parent="#accordion"
                      >
                        <div className="card-body">
                          <p> Of course, do not hesitate to
                            <Link to="/contact/"> contact us </Link>
                            !</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="service"
                  role="tabpanel"
                  aria-labelledby="service-tab"
                >
                  <div id="accordion-2" className="faq faq-two pixFade">
                    <div className="card">
                      <div className="card-header" id="heading101">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse01"
                            aria-expanded="false"
                            aria-controls="collapse01"
                          >
                            Can I request training on the software?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse01"
                        className="collapse"
                        aria-labelledby="heading101"
                        data-parent="#accordion-2"
                      >
                        <div className="card-body">
                          <p>Coming soon! Please follow the
                            <Link to="/tutorials/"> Tutorials </Link>
                            page where we have lots of useful descriptions to support you.</p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading201">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse101"
                            aria-expanded="false"
                            aria-controls="collapse101"
                          >
                            I got stuck. What should I do now?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse101"
                        className="collapse"
                        aria-labelledby="heading201"
                        data-parent="#accordion-2"
                      >
                        <div className="card-body">
                          <p>Please check out the
                            <Link to="/tutorials/"> Tutorials </Link>
                            page. If you still have problems, please send an email to
                            <a href="mailto:info@skillgo.io"> info@skillgo.io</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading301">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse201"
                            aria-expanded="false"
                            aria-controls="collapse201"
                          >
                            Are there monthly support packages?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse201"
                        className="collapse"
                        aria-labelledby="heading301"
                        data-parent="#accordion-2"
                      >
                        <div className="card-body">
                          <p>They are coming soon! Until then please follow the Skillgo page.</p>
                        </div>
                      </div>
                    </div>
                    {/* <div className="card">
                      <div className="card-header" id="heading401">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse301"
                            aria-expanded="false"
                            aria-controls="collapse301"
                          >
                            QUESTION PLACEHOLDER
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse301"
                        className="collapse"
                        aria-labelledby="heading401"
                        data-parent="#accordion-2"
                      >
                        <div className="card-body">
                          <p>
                            Easy peasy owt to do with me cras I don't want no agro what a load of
                            rubbish starkers absolutely bladdered, old tinkety tonk old fruit so I
                            said the full monty.
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="general"
                  role="tabpanel"
                  aria-labelledby="general-tab"
                >
                  <div id="accordion-3" className="faq faq-two pixFade">
                    <div className="card">
                      <div className="card-header" id="heading102">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse002"
                            aria-expanded="false"
                            aria-controls="collapse002"
                          >
                            How many interactive elements can I use?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse002"
                        className="collapse"
                        aria-labelledby="heading102"
                        data-parent="#accordion-3"
                      >
                        <div className="card-body">
                          <p>
                            Currently we have 7 interactive blocks and 7 question types
                            available in Skillgo.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading202">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse102"
                            aria-expanded="false"
                            aria-controls="collapse102"
                          >
                            What does adaptive practice mean?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse102"
                        className="collapse"
                        aria-labelledby="heading202"
                        data-parent="#accordion-3"
                      >
                        <div className="card-body">
                          <p>
                            It means that the difficulty of the exercises follows the student's
                            current level of knowledge.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading303">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse203"
                            aria-expanded="false"
                            aria-controls="collapse203"
                          >
                            Are the courses fully accessible?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse203"
                        className="collapse"
                        aria-labelledby="heading303"
                        data-parent="#accordion-3"
                      >
                        <div className="card-body">
                          <p>Yes, Skillgo has a
                            <a href="https://www.w3.org/TR/WCAG21/"> WCAG 2.1 Level A </a>
                            conformance.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading403">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse303"
                            aria-expanded="false"
                            aria-controls="collapse303"
                          >
                            Are the courses fully responsive?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse303"
                        className="collapse"
                        aria-labelledby="heading403"
                        data-parent="#accordion-3"
                      >
                        <div className="card-body">
                          <p>
                            Indeed, user experience and look and feel are preserved across all
                            devices.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="branding"
                  role="tabpanel"
                  aria-labelledby="branding-tab"
                >
                  <div id="accordion-4" className="faq faq-two pixFade">
                    <div className="card">
                      <div className="card-header" id="heading10">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse00"
                            aria-expanded="false"
                            aria-controls="collapse00"
                          >
                            Is Skillgo compatible with LMSs?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse00"
                        className="collapse"
                        aria-labelledby="heading10"
                        data-parent="#accordion-4"
                      >
                        <div className="card-body">
                          <p>
                            Yes, Skillgo is compatible with any LMS that supports the SCORM standard.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading20">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse10"
                            aria-expanded="false"
                            aria-controls="collapse10"
                          >
                            Is the SCORM standard supported?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse10"
                        className="collapse"
                        aria-labelledby="heading20"
                        data-parent="#accordion-4"
                      >
                        <div className="card-body">
                          <p>Yes of course, you can easily generate SCORM packages.</p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading30">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse20"
                            aria-expanded="false"
                            aria-controls="collapse20"
                          >
                            Is xAPI standard supported?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse20"
                        className="collapse"
                        aria-labelledby="heading30"
                        data-parent="#accordion-4"
                      >
                        <div className="card-body">
                          <p>Coming soon!</p>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading40">
                        <h5>
                          <button
                            className="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapse30"
                            aria-expanded="false"
                            aria-controls="collapse30"
                          >
                            What criteria can be set for LMS completion?
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapse30"
                        className="collapse"
                        aria-labelledby="heading40"
                        data-parent="#accordion-4"
                      >
                        <div className="card-body">
                          <p>Completion requires viewing all pages or passing the final test.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="scroll-circle wow pixFadeLeft">
          <img src="media/background/circle8.png" data-parallax='{"y" : 130}' alt="circle" />
        </div>
      </section>

      <section className="call-to-action">
        <div className="overlay-bg">
          <img src="media/background/ellipse.png" alt="bg" />
        </div>
        <div className="container">
          <div className="action-content text-center wow pixFadeUp">
            <h2 className="title">
              Embark on your journey <br />
              with Skillgo today
            </h2>

            <p>
              Interested? <br />
              We would love to be in touch with you.
            </p>

            <Link to="/contact" className="pix-btn btn-light">
              Contact Us
            </Link>
          </div>
        </div>

        <div className="scroll-circle">
          <img src="media/background/circle13.png" data-parallax='{"y" : -130}' alt="circle" />
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Index;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
